import React from 'react';
import StylePage from './page.style';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import dynamic from 'next/dynamic';

const Navbar = dynamic(() => import('../Navbar'));
const Footer = dynamic(() => import('../Footer'));

const Page = ({ children, locale,promotion }) => {
  return (
    <StylePage>
      <DrawerProvider>
        <Navbar locale={locale} promotion={promotion} />
      </DrawerProvider>
      {children}
      <Footer locale={locale} />
    </StylePage>
  );
};

export default Page;
