import styled from 'styled-components';

const StylePage = styled.div`
  font-variant-numeric: lining-nums;
  font-feature-settings: 'lnum';
  padding: 0;
  margin: 0;
  background: rgba(52, 50, 74, 1);
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  position: absolute;
  overflow-x: hidden;
`;

export default StylePage;
