import React, { useEffect } from 'react';
import { createClient } from '../prismicio';
import { Mixpanel } from 'utils/mixpanel';
import MetaHead from 'common/components/MetaHead';
import { getPrismicLanguage } from 'utils/getPrismicLanguage';
import Page from 'containers/SaasModern/Page';
import { SliceZone } from '@prismicio/react';
import { components } from '../slices';

const Main = ({ page, aiStoreData, botTagsData,promotion, locale }) => {
  useEffect(() => {
    Mixpanel.track('Landing Page - Main Page', {});
  }, []);

  const data = page?.data;

  return (
    <Page locale={locale} promotion={promotion}>
      <MetaHead
        title={page?.data?.meta_title}
        description={page?.data?.meta_description}
        keywords={page?.data?.meta_keywords}
        imageUrl={page?.data?.meta_image?.url}
      />
      <SliceZone
        slices={data?.slices}
        context={{ aiStoreData, botTagsData }}
        components={components}
      />
    </Page>
  );
};

export const getStaticProps = async ({ locale }) => {
  const client = createClient();
  const prismicLanguage = getPrismicLanguage(locale);
  // Fetch homepage content and additional data
  const page = await client.getSingle('homepage', {
    lang: prismicLanguage,
  });

  const sold = await client.getSingle('sold', {
    lang: prismicLanguage,
  });

  const datla = await client.getAllByType('subscription_plan_feature');
  const aiStore = await fetch(
    'https://graphql.swiftask.ai/public/bots_landing_page',
  );
  const botTags = await fetch(
    'https://graphql.swiftask.ai/public/bot_tags_List',
  );
  const aiStoreData = await aiStore.json();
  const botTagsData = await botTags.json();
  return {
    props: {
      page,
      datla,
      locale,
      botTagsData,
      aiStoreData,
      promotion: sold.data
    },
  };
};
export default Main;
