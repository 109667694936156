import Head from 'next/head';

const MetaHead = ({ title, description, keywords, imageUrl }) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name='Description' content={description} />
      <meta name='keywords' content={keywords} />
      <meta name='theme-color' content='#34324A' />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      {imageUrl && <meta property='og:image' content={imageUrl} />}
      <meta property='og:url' content='URL_to_your_page_here' />
    </Head>
  );
};

export default MetaHead;
